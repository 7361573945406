.image {
  margin: 20px 0;
  max-height: 600px;
  /* max-width: 700px; */
}

@media screen and (min-width: 600px) {
  .image {
    margin: 20px auto;
  }
}

a {
  text-decoration: underline;
}

.code {
  direction: ltr;
  font-family: Menlo, Monaco, 'Courier New', Courier, monospace;
  background: #979797;
  padding: 16px 8px 0;
  margin-bottom: 16px;
}
