@import "../variables.modules";

footer {
  color: $dark;
}

.dark {
  background-color: $dark;
  color: $white;
  transition: all 400ms;

  a, li {
    color: $white;
    transition: color 400ms;
  }

  a:visited {
    color: $white;
  }

  footer {
    color: $white;
  }
}

.light {
  background-color: $white;
  color: $dark;
  transition: all 400ms;
}

a, a:visited {
  color: $dark;
}



// .skill {
//   transition: $transition;

//   &:hover {
//     transform: translateY(-3px);
//     transition: $transition;
//   }
// }


