@import "../variables.modules";

nav {
  transition: $transition;
}

.active {
  border-bottom: 5px solid;
  border-image-slice: 1;
}

li {
  transition: $transition;

  &:hover {
    transform: translateY(-3px);
    transition: $transition;
  }
}

.headerLink {
  a,
  a:link,
  a:hover,
  a:visited,
  a:active {
    text-decoration: none;
  }

  a {
    color: $dark;
    transition: $transition;
  }

  a {
    color: $dark;
    transition: $transition;
  }
  a:hover {
    color: $lightgray;
      transition: $transition;
  }
}






